import React from "react"
import "./NotFound.styles.scss"
import { Divider, Tooltip } from "antd"
import Icon from "@ant-design/icons"
import CaretLeftIcon from "../../../Icons/CaretLeft.icons"

const NotFound = ({ handleClose }) => {
  return (
    <div className={"NotFound"}>
      <div className={"NotFound_Header"}>
        <Tooltip title="Back" placement="left">
          <Icon component={CaretLeftIcon} onClick={handleClose} />
        </Tooltip>
      </div>
      <h1>Not found</h1>
      <Divider />
      <div style={{ padding: 10 }}>
        <p>This person could not be found in your database</p>
      </div>
      <Divider />
    </div>
  )
}

export default NotFound
